import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMegaphone,
  faPlus,
  faRectangleHistory,
  faUserPlus
} from '@fortawesome/pro-regular-svg-icons';
import {
  hasPlanGreaterThanOrEqual,
  hasPlan,
  Plans
} from 'features/hive/hiveUtils';
import UpgradeModal from './UpgradeModal';

const DesktopCreateButton = ({
  openCreateAnnouncementModal,
  openCreateCardModal,
  openCreateCollectionModal,
  openInviteModal
}) => {
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);

  const { t } = useTranslation();
  const {
    isOpen: showUpgradeModal,
    onOpen: openUpgradeModal,
    onClose: closeUpgradeModal
  } = useDisclosure();

  return profile?.is_admin_or_manager ? (
    hasPlanGreaterThanOrEqual(hive?.plan, Plans.Starter) ? (
      <Menu placement="bottom">
        <MenuButton as={Button} colorScheme="teal">
          <FontAwesomeIcon icon={faPlus} />
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={openInviteModal}
            icon={<FontAwesomeIcon icon={faUserPlus} fixedWidth />}
          >
            <Text>{t('common.invite')}</Text>
          </MenuItem>
          <MenuItem
            onClick={openCreateCollectionModal}
            icon={<FontAwesomeIcon icon={faRectangleHistory} fixedWidth />}
          >
            <Text>{t('common.collection')}</Text>
          </MenuItem>
          <MenuItem
            onClick={openCreateAnnouncementModal}
            icon={<FontAwesomeIcon icon={faMegaphone} fixedWidth />}
          >
            <Text>{t('common.announcement')}</Text>
          </MenuItem>
        </MenuList>
      </Menu>
    ) : (
      <>
        <Button
          colorScheme="teal"
          onClick={() => {
            if (
              hasPlan(hive?.plan, Plans.Freemium) &&
              hive.metrics.collections >= 2
            ) {
              openUpgradeModal();
            } else {
              openCreateCollectionModal();
            }
          }}
        >
          <HStack>
            <FontAwesomeIcon icon={faPlus} />
            <Text>{t('common.collection')}</Text>
          </HStack>
        </Button>
        <UpgradeModal isOpen={showUpgradeModal} onClose={closeUpgradeModal} />
      </>
    )
  ) : (
    <Button colorScheme="teal" onClick={openCreateCardModal}>
      <HStack>
        <FontAwesomeIcon icon={faPlus} />
        <Text>{t('button.submit')}</Text>
      </HStack>
    </Button>
  );
};

export default DesktopCreateButton;
