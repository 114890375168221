import { Flex, HStack, Heading, Spacer } from '@chakra-ui/react';
const HeadingButton = ({
  title,
  button,
  fontSize = '2xl',
  alignItems = 'flex-start'
}) => {
  return (
    <HStack
      spacing={4}
      width="full"
      alignItems={alignItems}
      flexWrap="wrap"
      rowGap={4}
      flexGrow={2}
      height="fit-content"
    >
      <Heading
        noOfLines={99}
        width="fit-content"
        wordBreak="break-word"
        fontSize={fontSize}
        maxWidth={['full', null, '90%']}
        height="min-content"
      >
        {title}
      </Heading>
      <Spacer />
      <Flex flexGrow={1} alignSelf="flex-start" justify="flex-end">
        {button}
      </Flex>
    </HStack>
  );
};

export default HeadingButton;
