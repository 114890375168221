import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const FieldAccessText = field => {
  const { t } = useTranslation();

  const getFieldAccessList = (access, label) => {
    let list = [
      t('common.contributors'),
      t('common.assignees'),
      t('common.collection_managers'),
      t('common.managers'),
      t('common.admins'),
      t('common.panel')
    ];
    if (access === 'EVE') {
      list = [t('common.everyone')];
    }
    if (access === 'COL') {
      list = list.slice(2, list.length - 1);
    }
    if (field?.form || access === null) {
      list = list.slice(0, list.length - 1);
    }
    return (
      <ul>
        {list.map((item, index) => (
          <li style={{ listStyleType: 'circle' }} key={index}>
            {item}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <HStack
      justifyContent="space-between"
      spacing={8}
      flexWrap="wrap"
      rowGap={4}
    >
      <Stack spacing={2}>
        <HStack spacing={2}>
          <Heading fontSize="md">{t('template.field_visible_to')}</Heading>
          <FontAwesomeIcon opacity="50%" icon={faEye} />
        </HStack>
        <Box ml={4} pb={2}>
          {getFieldAccessList(field.read_access)}
        </Box>
      </Stack>
      <Stack spacing={2}>
        <HStack spacing={2}>
          <Heading fontSize="md">{t('template.field_answerable_by')}</Heading>
          <FontAwesomeIcon opacity="50%" icon={faEdit} />
        </HStack>
        <Box ml={4} pb={2}>
          {getFieldAccessList(field.write_access)}
        </Box>
      </Stack>
    </HStack>
  );
};

const FieldAccessButton = ({ field }) => {
  const {
    isOpen: showAccessModal,
    onOpen: openAccessModal,
    onClose: closeAccessModal
  } = useDisclosure();

  return (
    <>
      <Button
        maxWidth="fit-content"
        size="sm"
        variant="outline"
        onClick={openAccessModal}
      >
        <HStack spacing={0.5}>
          <FontAwesomeIcon icon={faEye} />
          <Text opacity="50%" fontWeight="100" fontSize="sx">
            /
          </Text>
          <FontAwesomeIcon icon={faEdit} />
        </HStack>
      </Button>
      <Modal isOpen={showAccessModal} onClose={closeAccessModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{field.field}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FieldAccessText />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FieldAccessButton;
