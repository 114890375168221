import { Reorder } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Flex,
  Stack,
  Table,
  Tbody,
  Td,
  useToast,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  HStack,
  Text
} from '@chakra-ui/react';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import FieldForm from 'features/workflow/FieldForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faGripDots } from '@fortawesome/pro-solid-svg-icons';
import api from 'utils/api';
import { getFieldTypeLabel } from './workflowUtils';
import OptionTable from './OptionTable';
import ChartForm from 'features/chart/ChartForm';
import ParameterTable from 'features/chart/ParameterTable';
import IconTooltip from 'components/tooltips/IconTooltip';

let timer = null;

const FieldTable = ({
  step,
  workflow,
  form = false,
  onFieldChangeStep,
  isOpen
}) => {
  const [selectedField, setSelectedField] = useState();
  const [allowManyAnswers, setAllowManyAnswers] = useState(false);

  const {
    isOpen: showCreateFieldModal,
    onOpen: openCreateFieldModal,
    onClose: closeCreateFieldModal
  } = useDisclosure();

  const {
    isOpen: showCreateRatingModal,
    onOpen: openCreateRatingModal,
    onClose: closeCreateRatingModal
  } = useDisclosure();

  const {
    isOpen: showCreateSelectFieldModal,
    onOpen: openCreateSelectFieldModal,
    onClose: closeCreateSelectFieldModal
  } = useDisclosure();

  const {
    isOpen: showDeleteFieldModal,
    onOpen: openDeleteFieldModal,
    onClose: closeDeleteFieldModal
  } = useDisclosure();

  const {
    isOpen: showUpdateFieldModal,
    onOpen: openUpdateFieldModal,
    onClose: closeUpdateFieldModal
  } = useDisclosure();

  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    status,
    data: fields,
    refetch,
    isSuccess
  } = useQuery(['fields', step?.id, workflow.id, form], async () => {
    const params = new URLSearchParams({
      workflow: workflow.id,
      form: form
    });

    if (step?.id) {
      params.append('step', step.id);
    } else {
      params.append('step__isnull', true);
    }

    const { data } = await api.get(`/fields?${params.toString()}`);
    return data;
  });

  const createFieldMutation = useMutation(field => api.post('/fields', field), {
    onSuccess: ({ data: field }) => {
      closeCreateFieldModal();
      closeCreateSelectFieldModal();
      closeCreateRatingModal();
      toast({
        title: t('toast.create_success', {
          entity: t('common.field')
        }),
        status: 'success'
      });
      queryClient.setQueryData(
        ['fields', step?.id, workflow.id, form],
        [...fields, field]
      );

      if (field.type === 'RATING') {
        setSelectedField(field);
        openUpdateFieldModal();
      }
      refetch();
    },
    onError: () => {
      toast({
        title: t('toast.create_error', {
          entity: t('common.field')
        }),
        status: 'error'
      });
    }
  });

  const deleteFieldMutation = useMutation(
    fieldId => api.delete(`/fields/${fieldId}`),
    {
      onSuccess: () => {
        closeDeleteFieldModal();
        queryClient.setQueryData(
          ['fields', step?.id, workflow.id, form],
          fields.filter(s => s.id !== selectedField.id)
        );
        toast({
          title: t('toast.delete_success', {
            entity: t('common.field')
          }),
          status: 'success'
        });
        refetch();
      }
    }
  );

  const updateFieldMutation = useMutation(
    field => api.patch(`/fields/${selectedField.id}`, field),
    {
      onSuccess: ({ data: field }) => {
        closeUpdateFieldModal();
        toast({
          title: t('toast.update_success', {
            entity: t('common.field')
          }),
          status: 'success'
        });
        queryClient.setQueryData(
          ['fields', step?.id, workflow.id, form],
          fields.map(f => (f.id === field.id ? field : f))
        );
        onFieldChangeStep(field);
        refetch();
      },
      onError: () => {
        toast({
          title: t('toast.update_error', {
            entity: t('common.field')
          }),
          status: 'error'
        });
      }
    }
  );

  const handleOnClickDelete = async () => {
    await deleteFieldMutation.mutateAsync(selectedField.id);
  };

  const handleOnCreateField = async (field, type = 'INPUT') => {
    await createFieldMutation.mutateAsync({
      ...field,
      workflow: workflow.id,
      step: step?.id,
      read_access: field.read_access ? field.read_access.id : 'COL',
      write_access: field.write_access ? field.write_access.id : 'COL',
      options: field.options ? field.options.map(({ option }) => option) : [],
      type,
      many: allowManyAnswers,
      form
    });
  };

  const handleOnCreateRating = async (field, type = 'RATING') => {
    await createFieldMutation.mutateAsync({
      ...field,
      average: field.average ? field.average.value : true,
      workflow: workflow.id,
      step: step?.id,
      read_access: field.read_access ? field.read_access.id : 'COL',
      write_access: field.write_access ? field.write_access.id : 'COL',
      type,
      many: true,
      form
    });
  };

  const handleOnUpdateField = async field => {
    await updateFieldMutation.mutateAsync({
      ...field,
      workflow: workflow.id,
      average: field.average ? field.average.value : true,
      step: field?.step?.id,
      read_access: field.read_access ? field.read_access.id : 'COL',
      write_access: field.write_access ? field.write_access.id : 'COL',
      form: field?.step?.form
    });
  };

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  const accessOptions = {
    COL: {
      id: 'COL',
      label: t('common.collection_managers')
    },
    CON: {
      id: 'CON',
      label: t('common.contributors')
    },
    EVE: {
      id: 'EVE',
      label: t('common.everyone')
    }
  };

  const getAccessOptions = id =>
    id === 'COL'
      ? accessOptions.COL
      : id === 'CON'
        ? accessOptions.CON
        : accessOptions.EVE;

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.fields').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <Stack spacing={4}>
          <Reorder.Group
            axis="y"
            values={fields}
            onReorder={fields => {
              if (timer != null) {
                clearTimeout(timer);
              }
              timer = setTimeout(() => {
                try {
                  const fields = queryClient.getQueryData([
                    'fields',
                    step?.id,
                    workflow.id,
                    form
                  ]);
                  api.patch(`/workflows/${workflow.id}/reorder-fields`, {
                    fields: fields.map(field => field.id)
                  });
                } catch (e) {
                  console.log(e);
                }
              }, 1000);
              queryClient.setQueryData(
                ['fields', step?.id, workflow.id, form],
                fields
              );
            }}
          >
            <Table variant="unstyled">
              <Tbody>
                {fields.map(field => (
                  <Reorder.Item as="tr" key={field.id} value={field}>
                    <Td pl={0}>
                      <HStack spacing={4}>
                        <Text cursor="grab" variant="light">
                          <FontAwesomeIcon icon={faGripDots} />
                        </Text>
                        <Button
                          variant="link"
                          onClick={() => {
                            setSelectedField(field);
                            openUpdateFieldModal();
                          }}
                        >
                          {field.field}
                        </Button>
                      </HStack>
                    </Td>
                    <Td textAlign="center">{getFieldTypeLabel(field)}</Td>
                    <Td pr={0} textAlign="end">
                      <Button
                        variant="outline"
                        onClick={() => {
                          setSelectedField(field);
                          openDeleteFieldModal();
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </Td>
                  </Reorder.Item>
                ))}
              </Tbody>
            </Table>
          </Reorder.Group>
          <Flex justifyContent="flex-start">
            <HStack alignItems="center" spacing={2}>
              <Menu>
                <MenuButton
                  as={Button}
                  variant="outline"
                  size="sm"
                  leftIcon={<FontAwesomeIcon icon={faPlus} />}
                >
                  {t('common.add_question')}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={openCreateFieldModal}>
                    {t('common.text_field')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAllowManyAnswers(false);
                      openCreateSelectFieldModal();
                    }}
                  >
                    {t('common.multi_select_field')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAllowManyAnswers(true);
                      openCreateSelectFieldModal();
                    }}
                  >
                    {t('common.voting')}
                  </MenuItem>
                  <MenuItem onClick={openCreateRatingModal}>
                    {t('common.rating')}
                  </MenuItem>
                </MenuList>
              </Menu>
              <IconTooltip
                label={t('workflow.add_question_tooltip')}
                color="gray"
                alignSelf="center"
              />
            </HStack>
          </Flex>
          <ModalWrapper
            title={t('template.create_field')}
            size="full"
            isOpen={showCreateFieldModal}
            onClose={closeCreateFieldModal}
          >
            <FieldForm
              defaultValues={{
                step,
                form,
                read_access: getAccessOptions('COL'),
                write_access: getAccessOptions('COL')
              }}
              workflow={workflow}
              onSubmit={async values =>
                await handleOnCreateField(values, 'INPUT')
              }
            />
          </ModalWrapper>
          <ModalWrapper
            title={t('template.create_rating')}
            size="full"
            isOpen={showCreateRatingModal}
            onClose={closeCreateRatingModal}
          >
            <ChartForm
              defaultValues={{
                step,
                form,
                read_access: getAccessOptions('COL'),
                write_access: getAccessOptions('COL')
              }}
              isOpen={showCreateRatingModal}
              onSubmit={async values => {
                await handleOnCreateRating(values, 'RATING');
              }}
              workflowId={workflow.id}
            />
          </ModalWrapper>
          <ModalWrapper
            title={
              allowManyAnswers
                ? t('template.create_vote')
                : t('template.create_multi_select_field')
            }
            size="full"
            isOpen={showCreateSelectFieldModal}
            onClose={closeCreateSelectFieldModal}
          >
            <FieldForm
              workflow={workflow}
              defaultValues={{
                step,
                form,
                read_access: getAccessOptions('COL'),
                write_access: getAccessOptions('COL')
              }}
              onSubmit={async values =>
                await handleOnCreateField(values, 'MULTI_SELECT')
              }
              addDefaultOptions
            />
          </ModalWrapper>
          <ModalWrapper
            title={getFieldTypeLabel(selectedField)}
            isOpen={showUpdateFieldModal}
            onClose={closeUpdateFieldModal}
            size="2xl"
          >
            <Stack>
              <FieldForm
                workflow={workflow}
                defaultValues={{
                  average: selectedField?.chart?.average,
                  field: selectedField?.field,
                  help_text: selectedField?.help_text,
                  form: selectedField?.form,
                  read_access: getAccessOptions(selectedField?.read_access),
                  write_access: getAccessOptions(selectedField?.write_access),
                  step
                }}
                onSubmit={handleOnUpdateField}
                addDefaultOptions={false}
              />

              {selectedField?.type !== 'INPUT' && <Divider />}
              {(selectedField?.type === 'MULTI_SELECT' ||
                selectedField?.type === 'SINGLE_SELECT') && (
                <OptionTable
                  fields={fields}
                  fieldId={selectedField?.id}
                  selectedField={selectedField}
                />
              )}
              {selectedField?.type === 'RATING' && selectedField?.chart?.id && (
                <ParameterTable chartId={selectedField.chart.id} />
              )}
            </Stack>
          </ModalWrapper>
          <ConfirmationModal
            deleteText={t(
              'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
              { data: t('common.field').toLowerCase() }
            )}
            isOpen={showDeleteFieldModal}
            onClose={closeDeleteFieldModal}
            onDelete={handleOnClickDelete}
          />
        </Stack>
      ) : null}
    </LoadingWrapper>
  );
};

export default FieldTable;
