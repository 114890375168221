import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Answerer from './Answerer';
import FieldAccessButton from 'components/FieldAccessButton';

const SelectAnswer = ({ answer, field, onClickEdit, forReport, locked }) => {
  const { t } = useTranslation();
  const selectedColor = useColorModeValue('teal.500', 'teal.200');
  return (
    <Stack spacing={6}>
      <Flex alignItems="center" justifyContent="space-between">
        <Heading fontSize="md">{field.field}</Heading>
        {!forReport && (
          <HStack spacing={2}>
            <FieldAccessButton field={field} />
            <Tooltip
              isDisabled={!locked}
              label={t('workflow.field_lock_current_step_explainer_simple')}
              hasArrow
              shouldWrapChildren
            >
              <Button
                isDisabled={locked}
                size="sm"
                variant="outline"
                onClick={onClickEdit}
              >
                {answer?.creator ? t('common.edit') : t('common.answer_verb')}
              </Button>
            </Tooltip>
          </HStack>
        )}
      </Flex>
      {answer && answer.options.length > 0 && (
        <>
          <SimpleGrid columns={[1, null, 2]} spacing={8} pb={2}>
            {answer.options.map(option => (
              <Box
                padding={4}
                borderWidth="1px"
                rounded="lg"
                borderTop="none"
                borderLeft="none"
              >
                <HStack key={option.id} opacity={option.selected ? 1.0 : 0.3}>
                  <Text
                    color={option.selected && selectedColor}
                    opacity={option.selected ? 1.0 : 0.8}
                  >
                    <FontAwesomeIcon
                      className="fa-fw"
                      icon={option.selected ? faCheck : faTimes}
                    />
                  </Text>
                  <Text>{option.option}</Text>
                </HStack>
              </Box>
            ))}
          </SimpleGrid>
          {answer.creator && !field.form && (
            <Answerer creator={answer.creator} answeredAt={answer.created_at} />
          )}
        </>
      )}
    </Stack>
  );
};

export default SelectAnswer;
